import {combineReducers} from 'redux'
import auth from '../helpers/auth';


function status(state = null, action) {
  if(!state && action.type !== "STATUS") return state;
  switch (action.type) {
    case "STATUS":
      return action.payload;
    case "DATE_STATUS":
      return {...state, date: action.payload};
    case "M1UPDATE_UPDATE":
    case "M1_UPDATE":
      return {...state, m1: m1(state.m1, action)};
    case "AVAILABLE_UPDATES_SUCCESS":
      const newm1 = state.m1;
      newm1.update = "";
      return {...state, m1:newm1};
    case "BUBBLE_UPDATE":
    case "SCAN_UPDATE":
    case "ANGLES_UPDATE":
    case "TILTCALIBRATION_RESULT":
    case "SEARCH_RESULT":
    case "VIDEOSETTINGS_UPDATE":
    case "TOTALSTATION_UPDATE":
      return {...state, totalstation: totalstation(state.totalstation, action)};
    case "GPS_UPDATE":
      return {...state, gps: gps(state.gps, action)};
    case "CONNECTION_UPDATE":
      return {...state, connection: connection(state.connection, action)};
    case "MONITORING_UPDATE":
      return {...state, monitoring: monitoring(state.monitoring, action)};
    case "DEVICES_UPDATE":
      return {...state, devices: action.payload};
    /*case "STATION_STATUS":
      return {...state, ...action.payload, search:null};
    case "SEARCH_RESULT":
      return {...state, search: (action.payload)?"Target found":"Target not found"};*/
    default:
      return state;
  }
}

function monitoring(state = null, action) {
  switch (action.type) {
    case "MONITORING_UPDATE":
      return {...state, ...action.payload};
    default:
      return state;
  }
}

function m1(state = null, action) {
  switch (action.type) {
    case "M1UPDATE_UPDATE":
      return {...state, update: {...state.update, ...action.payload}};
    case "M1_UPDATE":
      return {...state, ...action.payload};
    default:
      return state;
  }
}

function totalstation(state = null, action) {
  switch (action.type) {
    case "TOTALSTATION_UPDATE":
      if(action.payload.status) {
        return {...state, ...action.payload, search: null, tiltCalibration: null};
      }
      return {...state, ...action.payload};
    case "TILTCALIBRATION_RESULT":
      return {...state, tiltCalibration: action.payload};
    case "ANGLES_UPDATE":
      return {...state, angles: action.payload};
    case "BUBBLE_UPDATE":
      return {...state, bubble: action.payload};
    case "SCAN_UPDATE":
      return {...state, scan: action.payload};
    case "SEARCH_RESULT":
      return {...state, search: action.payload};
    case "VIDEOSETTINGS_UPDATE":
      return {...state, video_settings:{...state.video_settings, ...action.payload}};
    default:
      return state;
  }
}

function gps(state = null, action) {
  switch (action.type) {
    case "GPS_UPDATE":
      return {...state, ...action.payload};
    default:
      return state;
  }
}

function connection(state = null, action) {
  switch (action.type) {
    case "CONNECTION_UPDATE":
      return {...state, ...action.payload};
    default:
      return state;
  }
}

function projects(state={loading: false, projects:[]}, action) {
  switch (action.type) {
    case "PROJECTS_LIST_REQUEST":
      return {loading: true,  projects:[]};
    case "PROJECTS_LIST":
      let projectsById = {};
      action.payload.forEach((p)=>{
        projectsById[p.name] = p;
      });
      return { loading: false, projects:action.payload, projectsById};
    default:
      return state;
  }
}

function internetTests(state = [], action) {
  switch (action.type) {
    case "CONNECTION_TESTS_LIST_REQUEST":
      return [];
    case "CONNECTION_TESTS_LIST_ENDPOINT":
      return state.length > 0? state : action.payload;
    case "CONNECTION_TESTS_LIST":
      return action.payload;
    default:
      return state;
  }
}

function sftpSettings(state = {loading: false}, action) {
  switch (action.type) {
    case "SFTP_SETTINGS_REQUEST":
      return {loading: true};
    case "SFTP_SETTINGS":
      return {loading: false, settings: action.payload};
    default:
      return state;
  }
}

function currentProject(state = {loading: false}, action) {
  switch (action.type) {
    case "CURRENT_PROJECT_REQUEST":
      return {...state, loading: true};
    case "CURRENT_PROJECT":
      return {loading: false, currentProject:action.payload};
    default:
      return state;
  }
}

function lastMeasure(state = {error: false, loading: false}, action) {
  switch (action.type) {
    case "MEASURE_CLEAR":
      return {error: false, loading:false};
    case "MEASURE_REQUEST":
      return {error: false, loading: true};
    case "MEASURE_RESULT":
      if(!state.loading) return state;
      let measure = {...action.payload};
      //FACE2 RESETOFFSET / MEASURE
      if(measure.va > 200) {
        measure.ha = (measure.ha + 200) % 400;
        measure.va = 400 - measure.va;
        measure.raw_ha = (measure.raw_ha + 200) % 400;
      }
      return {error: false, loading:false, measure};
    case "MEASURE_ERROR":
      if(!state.loading) return state;
      return {error: true, loading:false, errorDescription: action.payload.description};
    default:
      return state;
  }
}

function lastFaceMeasure(state = {error: false, loading: false}, action) {
  switch (action.type) {
    case "MEASURE_CLEAR":
      return {error: false, loading:false};
    case "MEASURE_REQUEST":
      return {...state, error: false, loading: true};
    case "MEASURE_RESULT":
      if(!state.loading) return state;
      let measure = action.payload;
      const measureFace = {};
      if(measure.va > 200) {
        measureFace.face2 = measure;
      }else{
        measureFace.face1 = measure;
      }
      return {...state, error: false, loading:false, ...measureFace};
    case "MEASURE_ERROR":
      if(!state.loading) return state;
      return {...state, error: true, loading:false, errorDescription: action.payload.description};
    default:
      return state;
  }
}

function targetList(state = {loading: false, targets: [], targetsRaw: []}, action) {

  switch (action.type) {
    case "TARGETS_LIST":

      const getFaceById = (faces, id) => {
        const faceById = {};
        faces.forEach((f) => {faceById[f.face] = f;});
        return faceById[id];
      };

      let targets = action.payload.map(t => {
        let {name, height, constant, measure:meas, faces, id, image, zoom_level} = t;
        let face = "F1/F2";
        let measure;
        if(faces.length === 2) {
          measure = getFaceById(faces, "1");
        } else {
          face = "F1";
          measure = getFaceById(faces, "1");
          if(!measure) {
            face = "F2";
            measure = getFaceById(faces, "2");
          }
        }
        return {
          id,
          name,
          height,
          constant,
          measure:meas,
          face,
          sd: measure.sd,
          va: measure.va,
          ha: measure.ha,
          image,
          zoom_level
        };
      });
      return {loading:false, targets, targetsRaw: action.payload};
    case "TARGETS_LIST_REQUEST":
      return {loading:true, targets: [], targetsRaw: []};
    case "TARGETS_LIST_ERROR":
      return {loading:false, targets: [], targetsRaw: [], error: true};
    default:
      return state;
  }
}


function showVideoComponent(state = false, action) {
  switch (action.type) {
    case "VIDEO_FRAME":
      return false;
    case "VIDEO_FRAME_H264":
      return true;
    default:
      return state;
  }
}

function video(state = null, action) {
  switch (action.type) {
    case "VIDEO_FRAME":
      return action.payload;
    default:
      return state;
  }
}

function videoCalibration(state = null, action) {
  switch (action.type) {
    case "VIDEO_CALIBRATION":
      return action.payload;
    default:
      return state;
  }
}


function webcam(state = null, action) {
  switch (action.type) {
    case "WEBCAM_FRAME":
      return action.payload;
    default:
      return state;
  }
}

function videoSettings(state={brightness:0, zoom: 0}, action) {
  const maxLevel = 3;
  let zoom = 0;
  switch (action.type) {
    case "VIDEO_BRIGHTNESS":
      return {...state, brightness: action.payload};
    case "ZOOM_IN":
      zoom = Math.min(state.zoom + 1, maxLevel);
      return {...state, zoom};
    case "ZOOM_OUT":
      zoom = zoom = Math.max(state.zoom - 1, 0);
      return {...state, zoom};
    case "ZOOM_RESET":
      return {...state, zoom};
    default:
      return state;
  }
}

function stationStatus(state={laser:false, tracklight: false, mode:'Autolock', settings:{horizontal_range: 5, vertical_range: 5}, videoEnabled:false}, action) {
  switch (action.type) {
    case "SETTINGS_STATUS":
      return {...state, settings: action.payload};
    case "LASER_STATUS":
      return {...state, laser: action.payload};
    case "TRACKLIGHT_STATUS":
      return {...state, tracklight: action.payload};
    case "MODE_STATUS":
      return {...state, mode: action.payload};
    case "VIDEO_ENABLED":
      return {...state, videoEnabled: action.payload};
    default:
      return state;
  }
}

function projectFiles(state={loading: false, files:[]}, action) {
  switch (action.type) {
    case "PROJECTS_FILE_LIST_REQUEST":
      return {loading: true,  files:[]};
    case "PROJECTS_FILE_LIST":
      return {loading: false, files:action.payload};
    default:
      return state;
  }
}

const measureModesState = [{
  "id": 0,
  "name": "Manual",
  "code": "M"
},
{
  "id": 1,
  "name": "Autolock",
  "code": "A"
},
{
  "id": 2,
  "name": "DirectReflex",
  "code": "DR"
},
{
  "id": 3,
  "name": "FineLock",
  "code": "FL"
},
{
  "id": 4,
  "name": "LRFineLock",
  "code": "LR"
},
/*{
  "id": 5,
  "name": "Laser"
},
  {
    "id": 6,
    "name": "Multitrack"
  }*/
];

function measureModes(state = measureModesState) {
  return state;//.filter(t => t.name != "Manual" && t.name != "Laser");
}

function fullscreen(state = false, action) {
  switch(action.type) {
    case "SWICH_FULLSCREEN":
      return !state;
    default:
      return state
  }
}

function stationConfig(state = {loading:false, config:{}}, action) {
  switch(action.type) {
    case "STATION_CONFIG_REQUEST":
      return {...state, loading: true};
    case "STATION_CONFIG_SUCCESS":
      return {loading: false, config: action.payload};
    default:
      return state
  }
}

function monitoringConfig(state = {loading:false, config:{}}, action) {
  switch(action.type) {
    case "MONITORING_CONFIG_REQUEST":
      return {...state, loading: true};
    case "MONITORING_CONFIG_SUCCESS":
      let config = action.payload;
      config.sleep = config.sleep === 255 ? 1: config.sleep;
      return {loading: false, config};
    default:
      return state
  }
}

const roundsState = [
  {
    "id": 0,
    "description": "Continuous"
  },
  {
    "id": 1,
    "description": "1"
  },
  {
    "id": 2,
    "description": "2"
  },
  {
    "id": 3,
    "description": "3"
  },
  {
    "id": 4,
    "description": "4"
  },
  {
    "id": 5,
    "description": "5"
  },
  {
    "id": 6,
    "description": "10"
  }
];

function rounds(state = roundsState) {
  return state;
}

const ordersState = [
  {
    "id": 0,
    "description": "F1/F2"
  },
  {
    "id": 2,
    "description": "F1...F2"
  },
  {
    "id": 1,
    "description": "F1 only"
  }
];

function orders(state = ordersState) {
  return state;
}

const sleepsState = [
    /*{
      "id": 255,
      "description": "OFF"
    },*/
    {
      "id": 0,
      "description": "5 min"
    },
    {
      "id": 1,
      "description": "10 min"
    },
    {
      "id": 2,
      "description": "15 min"
    },
    {
      "id": 3,
      "description": "20 min"
    },
    {
      "id": 4,
      "description": "30 min"
    },
    {
      "id": 5,
      "description": "45 min"
    },
    {
      "id": 6,
      "description": "90 min"
    },
    {
      "id": 7,
      "description": "1 h"
    },
    {
      "id": 8,
      "description": "2 h",
      "intervals": [0,1]
    },
    {
      "id": 9,
      "description": "3 h",
      "intervals": [0,1,2]
    },
    {
      "id": 10,
      "description": "4 h",
      "intervals": [0,1,2,3]
    },
    {
      "id": 11,
      "description": "6 h",
      "intervals": [0,1,2,3,4,5]
    },
    {
      "id": 12,
      "description": "8 h",
      "intervals": [0,1,2,3,4,5,6,7,8]
    },
    {
      "id": 13,
      "description": "12 h",
      "intervals": [0,1,2,3,4,5,6,7,8,9,10,11]
    },
    {
      "id": 14,
      "description": "24 h",
      "intervals": [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    }
  ];

function sleeps(state = sleepsState) {
  return state;
}

function systemInfo(state = {loading:false}, action) {
  switch(action.type) {
    case "SERIALNUMBER_REQUEST":
      return {...state, loading:true};
    case "SERIALNUMBER_UPDATE":
      return {info:action.payload, loading:false};
    default:
      return state;
  }

}

function systemSatInfo(state = {loading:false}, action) {
  switch(action.type) {
    case "SATINFO_REQUEST":
      return {...state, loading:true};
    case "SATINFO_UPDATE":
      return {info:action.payload, loading:false};
    default:
      return state;
  }
}

function autopower(state = {loading:false, enabled: true}, action) {
  switch(action.type) {
    case "AUTOPOWER_REQUEST":
      return {...state, loading:true};
    case "AUTOPOWER_UPDATE":
      return {enabled:action.payload, loading:false};
    default:
      return state;
  }
}

function modemSettings(state = {loading:false}, action) {
  switch(action.type) {
    case "MODEMSETTINGS_REQUEST":
      return {...state, loading:true};
    case "MODEMSETTINGS_UPDATE":
      return {settings:action.payload, loading:false};
    default:
      return state;
  }
}

function bluetooth(state = {loading:false, scanning:false}, action) {
  switch(action.type) {
    case "BLUETOOTHSCAN_START":
      return {...state, scanning:true};
    case "BLUETOOTHSCAN_STOP":
      return {...state, scanning:false};
    case "BLUETOOTHSCAN_UPDATE":
      return {...state, otherStations:action.payload};
    case "BLUETOOTH_REQUEST":
      return {...state, loading:true};
    case "BLUETOOTH_DELETE": {
      const {linkedStations} = state;
      const sIndex = linkedStations.findIndex((s) => s.id === action.payload);
      linkedStations.splice(sIndex, 1);
      return {...state, linkedStations};
    }
    case "BLUETOOTHSTATUS_CONNECT":
      var {linkedStations} = state;
      let elem = linkedStations.find((s) => s.id === action.payload);
      elem.status = "Connected";
      return {...state, linkedStations};
    case "BLUETOOTH_CONNECT": {
      const {linkedStations, otherStations} = state;
      const sIndex = otherStations.findIndex((s) => s.id === action.payload);
      let station = otherStations[sIndex];
      station.status = "Connecting...";
      otherStations.splice(sIndex, 1);
      return {...state, otherStations, linkedStations: [...linkedStations, station]};
    }
    case "BLUETOOTH_UPDATE":
      return {linkedStations:action.payload, loading:false};
    default:
      return state;
  }
}

function wifi(state = {loading:false},action) {
  switch(action.type) {
    case "WIFICONFIG_REQUEST":
      return {loading:true};
    case "WIFICONFIG_UPDATE":
      return {loading: false, config:action.payload};
    case "WIFISSCAN_START":
      return {...state, scanning:true};
    case "WIFISSCAN_STOP":
      return {...state, scanning:false};
    case "WIFISSCAN_UPDATE":
      return {...state, availableWifis: action.payload};
    case "WIFICONNECT_START":
      return {...state, current: {ssid: action.payload, connected: false}};
    case "WIFICONNECT_END":
      let {current} = state;
      return {...state, current: {...current, connected: true}};
    default:
      return state;
  }

}

function deviceDisconnected( state = false, action) {
  switch(action.type) {
    case "DEVCONNECTION_UPDATE":
      return action.payload;
    default:
      return state;
  }
};

function octohub(state = {loading: false, ports:[]}, action) {
  switch(action.type) {
    case "OCTOHUB_REQUEST":
      return {...state, loading:true};
    case "OCTOHUB_UPDATE":
      return {ports:action.payload, loading:false};
    default:
      return state;
  }
}

function com1config(state = {loading: false, config:null}, action) {
  switch(action.type) {
    case "COM1CONFIG_REQUEST":
      return {...state, loading:true};
    case "COM1CONFIG_UPDATE":
      return {config:action.payload, loading:false};
    default:
      return state;
  }
}

function internal(state = {loading:false}, action) {
  switch(action.type) {
    case "INTERNAL_REQUEST":
      return {...state, loading:true};
    case "INTERNAL_UPDATE":
      return {settings:action.payload, loading:false};
    default:
      return state;
  }
}


function systemSatSettings(state = {loading:false}, action) {
  switch(action.type) {
    case "SATSETTINGS_REQUEST":
      return {...state, loading:true};
    case "SATSETTINGS_UPDATE":
      return {settings:action.payload, loading:false};
    default:
      return state;
  }
}

function gateway(state = {loading:false}, action) {
  switch(action.type) {
    case "GATEWAY_REQUEST":
      return {...state, loading:true};
    case "GATEWAY_UPDATE":
      return {settings:action.payload, loading:false};
    default:
      return state;
  }
}

function ethernet(state = {loading:false}, action) {
  switch(action.type) {
    case "ETHERNET_REQUEST":
      return {...state, loading:true};
    case "ETHERNET_UPDATE":
      return {settings:action.payload, loading:false};
    default:
      return state;
  }
}


let savedUser = auth.getUser();
const userInitialState = savedUser ? {loading:false, logged: true, user:savedUser} : {loading:false, logged: false};

function user(state = userInitialState, action) {
  switch(action.type) {
    case "LOGIN_REQUEST":
      return {loading:true, logged: false};
    case "LOGIN_FAIL":
      return {loading:false, logged: false, error:action.payload};
    case "LOGIN_SUCCESS":
      return {loading:false, logged: true, user: action.payload};
    case "LOGOUT":
      return {loading:false, logged: false};
    default:
      return state;
  }
}

function istconnectRegistration(state = {loading: false}, action) {
  switch(action.type) {
    case "ISTCONNECT_REGISTER_REQUEST":
      return {loading: true};
    case "ISTCONNECT_REGISTER_SUCCESS":
      return {loading: false};
    case "ISTCONNECT_REGISTER_ERROR":
      return {loading: false, error:action.payload};
    default:
      return state;
  }
}

function istconnectUpdate(state = {loading: false}, action) {
  switch(action.type) {
    case "ISTCONNECT_UPDATE_REQUEST":
      return {loading: true};
    case "ISTCONNECT_UPDATE_SUCCESS":
      return {loading: false};
    case "ISTCONNECT_UPDATE_ERROR":
      return {loading: false, error:action.payload};
    default:
      return state;
  }
}

function istconnectDelete(state = {loading: false}, action) {
  switch(action.type) {
    case "ISTCONNECT_DELETE_REQUEST":
      return {loading: true};
    case "ISTCONNECT_DELETE_SUCCESS":
      return {loading: false};
    case "ISTCONNECT_DELETE_ERROR":
      return {loading: false, error:action.payload};
    default:
      return state;
  }
}


function password(state = {loading: false}, action) {
  switch(action.type) {
    case "PASSWORD_REQUEST":
      return {loading: true};
    case "PASSWORD_FAIL":
      return {loading: false, error:true};
    case "PASSWORD_SUCCESS":
      return {loading: false};
    default:
      return state;
  }
}

function istports(state = {loading: false, ports:[]}, action) {
  switch(action.type) {
    case "ISTPORTS_REQUEST":
      return {loading: true};
    case "ISTPORTS_SUCCESS":
      return {loading: false, ports: action.payload};
    default:
      return state;
  }
}

function webcamSettings(state = {loading: false}, action) {
  switch(action.type) {
    case "WEBCAMSETTINGS_REQUEST":
      return {loading: true};
    case "WEBCAMSETTINGS_FAIL":
      return {loading: false, error:true};
    case "WEBCAMSETTINGS_UPDATE_FAIL":
      return {...state, loading: false, error:action.payload};
    case "WEBCAMSETTINGS_SUCCESS":
      return {loading: false, settings: action.payload};
    default:
      return state;
  }
}

function userConfig(state = {loading: false}, action) {
  switch(action.type) {
    case "USER_CONFIG_REQUEST":
      return {loading: true};
    case "USER_CONFIG_SUCCESS":
      return {loading: false, ...action.payload};
    default:
      return state;
  }
}

function targetActionsState(state = {loading: false}, action) {
  switch(action.type) {
    case "UPDATE_TARGET_SUCCESS":
    case "DELETE_TARGET_SUCCESS":
    case "CREATE_TARGET_SUCCESS":
      return {loading: false};
    case "UPDATE_TARGET_ERROR":
    case "DELETE_TARGET_ERROR":
    case "CREATE_TARGET_ERROR":
      return {loading: false, error: action.payload};
    case "UPDATE_TARGET_REQUEST":
    case "DELETE_TARGET_REQUEST":
    case "CREATE_TARGET_REQUEST":
      return {loading: true};
    default:
      return state;
  }
}

function projectActionsState(state = {loading: false}, action) {
  switch(action.type) {
    case "UPDATE_PROJECT_SUCCESS":
    case "DELETE_PROJECT_SUCCESS":
    case "CREATE_PROJECT_SUCCESS":
    case "UPLOAD_PROJECT_SUCCESS":
      return {loading: false};
    case "UPDATE_ACTIVEPROJECT_ERROR":
    case "UPDATE_PROJECT_ERROR":
    case "DELETE_PROJECT_ERROR":
    case "UPLOAD_PROJECT_ERROR":
    case "CREATE_PROJECT_ERROR":
      return {loading: false, error: action.payload};
    case "UPDATE_PROJECT_REQUEST":
    case "DELETE_PROJECT_REQUEST":
    case "UPLOAD_PROJECT_REQUEST":
    case "CREATE_PROJECT_REQUEST":
      return {loading: true};
    case "UPDATE_ACTIVEPROJECT_REQUEST":
      return {loading: state.loading};
    case "UPLOAD_PROJECT_CONFLICT":
      return {loading: false, overwrite: true};
    default:
      return state;
  }
}

function availableUpdates(state = {loading: false}, action) {
  switch(action.type) {
    case "AVAILABLE_UPDATES_SUCCESS":
      return {loading: false, update: action.payload};
    case "AVAILABLE_UPDATES_ERROR":
      return {loading: false, error: action.payload};
    case "AVAILABLE_UPDATES_REQUEST":
      return {loading: true};
    default:
      return state;
  }
}

function updateProgress(state = {loading: false}, action) {
  switch(action.type) {
    case "UPDATE_REQUEST":
      return {loading: true, message:"Starting update..."};
    case "UPDATE_FAIL":
      return {loading: false, message:"Update error, please try again", error: true};
    case "UPDATE_SUCCESS":
      return {loading: false};
    default:
      return state;
  }
}

function memoryLoading(state = false, action) {
  switch(action.type) {
    case "INTERNAL_MEMORY_LOADING":
      return true;
    case "INTERNAL_MEMORY_SUCCESS":
    case "INTERNAL_MEMORY_ERROR":
      return false;
    default:
      return state;
  }
}

function NTPServers(state= {loading: false}, action) {
  switch (action.type) {
    case "GET_NTP_SERVER_LOADING":
      return {loading: true};
    case "GET_NTP_SERVER_SUCCESS":
      return {loading: false, servers: action.payload};
    case "GET_NTP_SERVER_ERROR":
      return {loading: false, error: action.payload};
    default:
      return state;
  }
}

function projectsStorage(state = {loading: false, storage:[]}, action) {
  switch(action.type) {
    case "PROJECTS_STORAGE_REQUEST":
      return {loading: true, storage:[]};
    case "PROJECTS_STORAGE_LIST_ENDPOINT":
      if(state.storage.length > 0) return state;
      return {loading: false, storage: action.payload};
    case "PROJECTS_STORAGE_LIST":
      return {loading: false, storage: action.payload};
    default:
      return state;
  }
}

function storageInfo(state = {loading: false}, action) {
  switch(action.type) {
    case "GET_STORAGE_INFO_LOADING":
      return {loading: true};
    case "GET_STORAGE_INFO_SUCCESS":
      return {loading: false, info: action.payload};
    default:
      return state;
  }
}

function tethering(state = {loading: false}, action) {
  switch(action.type) {
    case "TETHERING_REQUEST":
      return {loading: true};
    case "TETHERING_UPDATE":
      return {loading: false, value: action.payload};
    default:
      return state;
  }
}

function internalRadioInfo(state = {loading: false}, action){
  switch(action.type){
    case "GET_INTERNAL_RADIO_INFO_LOADING":
      return {loading: true};
    case "GET_INTERNAL_RADIO_INFO_SUCCESS":
      return {loading: false, value: action.payload};
    case "GET_INTERNAL_RADIO_INFO_ERROR":
      return {loading: false, error: action.payload};
    default:
      return state;
  }
}

const powersState = [
  {
    id:0,
    power:"0.1W"
  },
  {
    id:1,
    power:"0.5W"
  },
  {
    id:2,
    power:"1W"
  },
  {
    id:3,
    power:"2W"
  },
];

function powers(state = powersState){
  return state;
}

const root = combineReducers({
  status,
  video,
  videoSettings,
  showVideoComponent,
  stationStatus,
  projects,
  currentProject,
  targetList,
  lastMeasure,
  measureModes,
  fullscreen,
  projectFiles,
  stationConfig,
  monitoringConfig,
  rounds,
  orders,
  sleeps,
  systemInfo,
  autopower,
  systemSatInfo,
  modemSettings,
  bluetooth,
  wifi,
  deviceDisconnected,
  octohub,
  internal,
  systemSatSettings,
  gateway,
  ethernet,
  user,
  istconnectRegistration,
  istconnectUpdate,
  istconnectDelete,
  webcam,
  password,
  istports,
  webcamSettings,
  userConfig,
  targetActionsState,
  projectActionsState,
  availableUpdates,
  updateProgress,
  memoryLoading,
  NTPServers,
  lastFaceMeasure,
  com1config,
  videoCalibration,
  projectsStorage,
  storageInfo,
  tethering,
  internalRadioInfo,
  powers,
  internetTests,
  sftpSettings
});

export default root;


